export const BaseUrl = "https://api.digidine-menu.com/api";
export const STORAGE = "https://api.digidine-menu.com/";
// export const BaseUrl = "http://127.0.0.1:8000/api";
// export const STORAGE = "http://127.0.0.1:8000/";
export const RESERVATION = `${BaseUrl}/table/reservation`;
export const CATEGORIES = `${BaseUrl}/category`;
export const ITEMS = `${BaseUrl}/item`;
export const ABOUT = `${BaseUrl}/about`;
export const SOCIAL = `${BaseUrl}/about/socialMedia`;
export const SEARCH = `${BaseUrl}/search`;
export const NEED_HELP = `${BaseUrl}/table/needHelp`;
export const BILL = `${BaseUrl}/table/bill`;
export const PRIVACY = `${BaseUrl}/table/privacy`;
export const ORDER = `${BaseUrl}/order`;
export const ORDER_TABLE = `${BaseUrl}/order/table`;
export const TIMER = `${BaseUrl}/jwt_TTL`;
export const CURRENCY = `${BaseUrl}/currency`;
export const getLangs = `${BaseUrl}/translations_languages`;
export const getLangsFile = (langCode) => `${BaseUrl}/tr_files/${langCode}`;
