import { TabContext, TabList } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Tab } from "@mui/material";
import FilterItem from './FilterItem';
import { ITEMS, STORAGE } from '../../constants/urls';
import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import ItemBox from './ItemBox';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import OrderContext from '../../store/OrderContext';
import Currency from './Currency';
import i18n from '../../i18n';

const CategoryBar = () => {
    const curr_lang = i18n.language;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const orderContext = useContext(OrderContext);
    const token = localStorage.getItem('token');
    const catResult = JSON.parse(localStorage.getItem("result"));
    const [value, setValue] = useState(window.location.href.substring(window.location.href.lastIndexOf("/") + 1) || 'trend');
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState([]);
    const [tempInfo, setTempInfo] = useState([]);
    const [select, setSelect] = useState('');
    const [reload, setReload] = useState(false);
    const [cachedData, setCachedData] = useState(null); // New state for caching data

    const FetchItems = useCallback(async () => {
        setLoading(true);
        if (cachedData) {
            setInfo(cachedData);
            setTempInfo(cachedData);
            setLoading(false);
        } else if (token != null) {
            await axios.get(ITEMS, { headers: { 'Currency': orderContext.currencyState || 'IQD' } })
                .then((response) => {
                    if (response.status === 200) {
                        setInfo(response.data.data);
                        setTempInfo(response.data.data);
                        setCachedData(response.data.data); // Cache the data
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        window.location.reload();
                        localStorage.removeItem('token');
                        localStorage.removeItem("new");
                        localStorage.removeItem('total');
                        orderContext.items = [];
                        orderContext.totalPrice = 0;
                        navigate("/");
                    }
                });
        } else {
            navigate("/");
        }
        setLoading(false);
    }, [token, navigate, orderContext, cachedData]);

    const ResetSearch = () => {
        orderContext.setresSearch1("");
        orderContext.setSearch(null);
    };

    const handleSelect = (value) => {
        setSelect(value);
        if (value !== 'all') {
            const newInfo = info.filter(e => (e[value] === 1));
            setTempInfo(newInfo);
        } else {
            setTempInfo(info);
        }
    };

    useEffect(() => {
        FetchItems();
    }, [FetchItems, reload, orderContext.currencyState]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(newValue);
    };

    const truncateText = (text) => {
        return text.length > 9 ? text.substring(0, 9) + '.' : text;
    };

    return (
        <>
            {orderContext.resSearch == null ?
                <TabContext value={value}>
                    <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                        <Tab label={t("Trended")} value='trend' icon={<div className='icon-box'><img src={require('../../assets/img/Vector2.png')} alt="icon" /></div>} />
                        {catResult.map((item) => {
                            const iconUrl = item.icon != null ? `${STORAGE}${item.icon}` : `${STORAGE}defaultIcon.png`;
                            return (
                                <Tab key={item.id} label={truncateText(item.translations.name[curr_lang])} value={item.id + ""}
                                    icon={<div className='icon-box'><img src={iconUrl} alt="category" /></div>}
                                />
                            );
                        })}
                    </TabList>
                    <div className='d-flex justify-content-between select-group'>
                        <FilterItem select={select} setSelect={handleSelect} />
                        <Currency reload={reload} setReload={setReload} />
                    </div>

                    {loading ? <LoadingSkeleton /> :
                        <>
                            <TabPanel value="trend" className='tap-last'>
                                {tempInfo.filter(item => item.is_trending === 1 || item.is_trending === '1').map((i) => (
                                    <ItemBox item_box={i} num={num} setNum={setNum} key={i.id} />
                                ))}
                            </TabPanel>

                            {catResult.map((category) => (
                                <TabPanel value={category.id.toString()} key={category.id} className="tap-last">
                                    {tempInfo.filter(item => item.category_id.toString() === category.id.toString()).map((i) => (
                                        <ItemBox item_box={i} num={num} setNum={setNum} key={i.id} />
                                    ))}
                                </TabPanel>
                            ))}
                        </>
                    }
                </TabContext>
                :
                <>
                    {orderContext.resSearch.length !== 0 ?
                        orderContext.resSearch.map((res) => (
                            <ItemBox item_box={res} num={num} setNum={setNum} key={res.id} />
                        )) :
                        <div className="text-center py-5">
                            <h5 className="text-center mb-4">{t('NoInformationSearch')}</h5>
                            <button onClick={ResetSearch} className="button-search">{t('backBtn')}</button>
                        </div>
                    }
                </>
            }
        </>
    );
};

export default CategoryBar;
