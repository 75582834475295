import axios from "axios";
import { createContext, useEffect } from "react";
import useStateRef from "react-usestateref";
import { getLangs } from "../constants/urls";

const LangContext = createContext({
  langs: [],
  get_langs: () => {},
});

export function LangContextProvider(props) {
  const [langs, setLangs, langsRef] = useStateRef([]);

  useEffect(() => {
    axios
      .get(getLangs)
      .then((res) => {
        if (res.data.status === "success" && Array.isArray(res.data.data)) {
          setLangs(res.data.data);
        } else {
          console.error("Invalid data format", res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching languages", error);
      });
  }, [setLangs]);

  const getLangsFunction = () => {
    return langsRef.current;
  };

  const context = {
    langs: langs,
    get_langs: getLangsFunction,
  };

  return (
    <LangContext.Provider value={context}>
      {props.children}
    </LangContext.Provider>
  );
}

export default LangContext;
