import { useState, useEffect, useContext, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Container,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import PreviosPage from '../../components/PreviosPage';
import SearchBar from '../../components/SearchBar';
import OrderContext from '../../store/OrderContext';
import ItemOrder from './ItemOrder';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import { ORDER, ORDER_TABLE, STORAGE, TIMER } from '../../constants/urls';
import i18n from '../../i18n';
import { getStoredWhatsAppNumber } from '../../utils/utils';

const OrdersPage = () => {
  const { t } = useTranslation();
  const curr_lang = i18n.language;
  const orderContext = useContext(OrderContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState('');
  const [message, setMessage] = useState(false);
  const [info, setInfo] = useState([]);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') return;
    setMessage(false);
  };

  const fetchTTLValue = useCallback(async () => {
    try {
      const response = await axios.get(TIMER);
      setTimer(response.data.data.JWT_TTL);
    } catch (error) {
      console.error('Error fetching TTL value:', error);
    }
  }, []);

  const fetchOrdersTable = useCallback(async () => {
    setInfoLoaded(true);
    try {
      const response = await axios.get(`${ORDER_TABLE}?page=${page}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          Currency: orderContext.currencyState,
        },
      });
      if (response.status === 200) {
        setInfo(response.data.data);
        setTotalPage(response.data.meta.last_page);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        navigate('/');
      }
    }
    setInfoLoaded(false);
  }, [page, token, orderContext.currencyState, navigate]);

  const finishOrder = async (e) => {
    setLoading(true);
    const btnValue = e.target.value;
  
    let lastOrder;
    try {
      lastOrder = JSON.parse(btnValue);
    } catch (error) {
      console.error("Error parsing last order", error);
      setLoading(false);
      return;
    }
  
    const data = {
      reservation_id: localStorage.getItem('reservation_id'), // Assuming you store the reservation ID in localStorage
      last_order: lastOrder,
      items: orderContext.items,
    };
  
    try {
      const response = await axios.post(ORDER, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        setOpen(false);
        setMessage(true);
        localStorage.removeItem('new');
        localStorage.removeItem('total');
        localStorage.removeItem('reservation_id'); // Optionally remove the reservation ID if it's a one-time use
        orderContext.reset(); // Clear items and total price
        fetchOrdersTable();
  
        // Send WhatsApp message
        const whatsappNumber = getStoredWhatsAppNumber();
        const tableNumber = localStorage.getItem('table_number'); // Assuming you store the table number in localStorage
  
        if (whatsappNumber) {
          const orderDetails = orderContext.items.map(item => (
            `Item: ${item.name}\nQuantity: ${item.quantity}\nPrice: ${item.price}${item.notes ? `\nNotes: ${item.notes}` : ''}`
          )).join('\n\n');
          
          const message = `New Order Received:\n\nTable Number: ${tableNumber}\n\n${orderDetails}\n\nTotal: ${orderContext.totalPrice} ${orderContext.currencyState}`;
          window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`, "_blank");
        }
      }
    } catch (error) {
      console.error('Error finishing order:', error);
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        navigate('/');
      }
    }
    setLoading(false);
  };

  const handleClose = (event) => {
    setOpen(false);
    setTimeout(() => finishOrder(event), timer);
  };

  const resetSearch = () => {
    orderContext.setresSearch1('');
    orderContext.setSearch(null);
  };

  useEffect(() => {
    fetchTTLValue();
    fetchOrdersTable();
  }, [page, orderContext.currencyState, fetchTTLValue, fetchOrdersTable]);

  return (
    <Container maxWidth="sm" className="pt-4 bg-white page">
      <div className="row align-items-center mb-4">
        <div className="col-md-3 col-sm-2 col-2">
          <PreviosPage />
        </div>
        <div className="col-md-6 col-sm-7 col-7">
          <h2 className="title fw-bold text-center mb-0" style={{ color: '#FAA61A' }}>
            {t('myOrder_link')}
          </h2>
        </div>
        <div className="col-md-3 col-sm-3 col-3 co-total">
          <div className="total fw-bold text-center">
            {orderContext.totalPrice} {orderContext.currencyState}
          </div>
        </div>
      </div>
      <SearchBar />
      {orderContext.resSearch == null ? (
        <div className="mt-4">
          {orderContext.items.length !== 0 ? (
            orderContext.items.map((item) => <ItemOrder item_box={item} key={item.id} />)
          ) : infoLoaded ? (
            <LoadingSkeleton />
          ) : info.length !== 0 ? (
            info.map((i) => (
              <div className="main-box position-relative" key={i.id}>
                {i.Items.length > 0 &&
                  i.Items.map((it) => (
                    <div className="box position-relative box-item box2" key={it.id} id={it.id}>
                      <div className="order-status">
                        {t('Order_Status')}: {i.order_status}
                      </div>
                      <div className="box-img mb-2 position-relative">
                        <img src={it.image ? `${STORAGE}${it.image}` : ''} alt="category" width="100%" />
                      </div>
                      <p className="fw-bold mb-2">{it.translations.name[curr_lang]}</p>
                      <div className="row align-items-center mb-2">
                        <div className="col-6">
                          <div className="price mb-0">
                            {it.offer_price !== null ? (
                              <div>
                                <span className="new-price fw-bold">
                                  {it.offer_price} {orderContext.currencyState}
                                </span>
                                <span className="old-price">
                                  {it.price} {orderContext.currencyState}
                                </span>
                              </div>
                            ) : (
                              <div className="new-price fw-bold">
                                {it.price} {orderContext.currencyState}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <p className="mb-0">
                            {t('Quantity')}: <span className="fw-bold">{it.pivot.quantity}</span>
                          </p>
                        </div>
                      </div>
                      {it.pivot.notes && (
                        <p>
                          {t('note_text')}: <span>{it.pivot.notes}</span>
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <Typography variant="h6" className="text-center pb-5" sx={{ mb: 5 }}>
              {t('NoInformationOrder')}
            </Typography>
          )}
          <Stack spacing={2} sx={{ padding: 2 }}>
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              variant="outlined"
              sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
            />
          </Stack>
        </div>
      ) : orderContext.resSearch.length !== 0 ? (
        orderContext.resSearch.map((res) =>
          info.map((i) => (
            <div className="main-box position-relative" key={i.id}>
              {i.Items.length > 0 &&
                i.Items.map((it) => (
                  <div className="box position-relative box-item box2" key={it.id}>
                    <div className="order-status">
                      {t('Order_Status')}: {i.order_status}
                    </div>
                    <div className="box-img mb-2 position-relative">
                      <img src={it.image ? `${STORAGE}${it.image}` : ''} alt="category" width="100%" />
                    </div>
                    <p className="fw-bold mb-2">{it.translations.name[curr_lang]}</p>
                    <div className="row align-items-center mb-2">
                      <div className="col-6">
                        <div className="price mb-0">
                          {it.offer_price !== null ? (
                            <div>
                              <span className="new-price fw-bold">
                                {it.offer_price} {orderContext.currencyState}
                              </span>
                              <span className="old-price">
                                {it.price} {orderContext.currencyState}
                              </span>
                            </div>
                          ) : (
                            <div className="new-price fw-bold">
                              {it.price} {orderContext.currencyState}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <p className="mb-0">
                          {t('Quantity')}: <span className="fw-bold">{it.pivot.quantity}</span>
                        </p>
                      </div>
                    </div>
                    {it.pivot.notes && (
                      <p>
                        {t('note_text')}: <span>{it.pivot.notes}</span>
                      </p>
                    )}
                  </div>
                ))}
            </div>
          )),
        )
      ) : (
        <div className="text-center py-5">
          <h5 className="text-center mb-4">No Result</h5>
          <button onClick={resetSearch} className="button-search">
            Back to menu
          </button>
        </div>
      )}
      {orderContext.items.length !== 0 && (
        <Container maxWidth="sm" className="bottom-footer px-0" sx={{ padding: '0' }}>
          <button className="btn-order" onClick={handleClickOpen}>
            {t('button_send')}
          </button>
        </Container>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Poppins', color: '#4C4C47' }}>
          {t('modal_title')}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          {loading ? (
            <Button>
              <CircularProgress size={25} style={{ color: '#fff' }} />
            </Button>
          ) : (
            <Button onClick={finishOrder} value="true">
              {t('yesButton')}
            </Button>
          )}
          <Button onClick={handleClose} value="false">
            {t('noButton')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={4000} onClose={handleCloseMessage} className="ic">
        <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }} style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}>
          {t('success-message')}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default OrdersPage;
