// utils.js
export const getStoredWhatsAppNumber = () => {
    const storedData = localStorage.getItem("whatsappNumber");
    if (!storedData) return null;
  
    try {
      const { whatsappNumber, expirationTime } = JSON.parse(storedData);
      const currentTime = new Date().getTime();
  
      if (currentTime > expirationTime) {
        localStorage.removeItem("whatsappNumber");
        return null;
      }
  
      return whatsappNumber;
    } catch (error) {
      console.error("Error parsing WhatsApp number from local storage", error);
      return null;
    }
  };
  