import { useContext } from "react";
import PreviosPage from "../../components/PreviosPage";
import SearchBar from "../../components/SearchBar";
import CategoryBar from "./CategoryBar";
import { Container } from "@mui/material";
import OrderContext from "../../store/OrderContext";
import { useTranslation } from "react-i18next";

const ItemPage = () => {
    const orderContext = useContext(OrderContext);
    const { t } = useTranslation();
   
    return (
        <Container maxWidth="sm" className="pt-4 bg-white page">
            <div className='row align-items-center mb-4'>
                <div className='col-md-3 col-sm-2 col-2'> <PreviosPage /> </div>
                <div className='col-md-6 col-sm-7 col-7'>
                    <h2 className="title fw-bold text-center mb-0" style={{ color: '#FAA61A' }}>{t("home_title")}</h2>
                </div>
                <div className='col-md-3 col-sm-3 col-3 co-total'>
                    <div className="total fw-bold text-center"> {orderContext.totalPrice} {orderContext.currencyState}</div>
                </div>
            </div>
            <SearchBar />
            <CategoryBar />
        </Container>
    );
}

export default ItemPage;