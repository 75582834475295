import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { CURRENCY } from "../../constants/urls";
import { useContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import OrderContext from "../../store/OrderContext";
import { useTranslation } from "react-i18next";

const Currency = (props) => {
    const { t } = useTranslation();
    const orderContext = useContext(OrderContext);
    const token = localStorage.getItem('token');
    const [list, setList] = useState([]);

    const fetchCurrency = useCallback(async () => {
        try {
            const response = await axios.get(CURRENCY, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200) {
                setList(response.data.data);
                localStorage.setItem('currencyData', JSON.stringify(response.data.data));
                localStorage.setItem('currencyFetchTime', new Date().getTime());

                if (!orderContext.currencyState || !response.data.data.some(item => item.currency === orderContext.currencyState)) {
                    orderContext.currencyStateFun("IQD");
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [orderContext, token]);

    useEffect(() => {
        const cachedData = localStorage.getItem('currencyData');
        const fetchTime = localStorage.getItem('currencyFetchTime');
        const currentTime = new Date().getTime();
        const cacheDuration = 24 * 60 * 60 * 1000; // 24 hours

        if (cachedData && fetchTime && (currentTime - fetchTime < cacheDuration)) {
            setList(JSON.parse(cachedData));
        } else {
            fetchCurrency();
        }
    }, [fetchCurrency]);

    const handleChange = (value) => {
        orderContext.currencyStateFun(value);
        localStorage.removeItem("new");
        localStorage.removeItem('total');
        orderContext.reset();
        props.setReload(!props.reload);
    };

    return (
        <div className="filter-section">
            <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="demo-simple-select-filled-label">{t("currency_title")}</InputLabel>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={orderContext.currencyState}
                    onChange={(e) => handleChange(e.target.value)}>
                    {list.length > 0 ?
                        list.map((item) => (
                            <MenuItem value={item.currency} key={item.id}>{item.currency}</MenuItem>
                        ))
                        : <Typography variant="body1" className="text-center" style={{ fontSize: '14px', padding: '5px' }} sx={{ mb: 5 }}>
                            No currency yet
                        </Typography>}
                </Select>
            </FormControl>
        </div>
    );
};

export default Currency;
