import { STORAGE } from "../../constants/urls";
import emptyImage from "../../assets/img/no-image.png";
import { TextField } from "@mui/material";
import { useContext, useEffect } from "react";
import OrderContext from "../../store/OrderContext";
import useStateRef from "react-usestateref";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const ItemBox = ({ item_box }) => {
  const orderContext = useContext(OrderContext);
  const [note, setNote, noteRef] = useStateRef("");
  const [noteBtn, setNoteBtn] = useStateRef(false);
  const [show, setShow] = useStateRef(false);
  const [quan, setQuan, quanRef] = useStateRef(0);
  const { t } = useTranslation();
  const curr_lang = i18n.language;

  useEffect(() => {
    orderContext?.items?.forEach((item) => {
      if (item.id === item_box.id) {
        setNoteBtn(true);
        setNoteBtn(true);
        setNote(item.notes);
        setShow(true);
        setQuan(parseInt(item.quantity));
      }
    });
  }, [item_box.id, orderContext.items, setNote, setNoteBtn, setQuan, setShow]);

  const incNum = () => {
    setQuan((prevQuan) => prevQuan + 1);
    item_box.offer_price != null
      ? orderContext.setTotal((prev) => prev + item_box.offer_price)
      : orderContext.setTotal((prev) => prev + item_box.price);

    const existing = orderContext.checkHandlerfun(item_box.id);
    if (existing) {
      let tempitem = {
        ...item_box,
        quantity: quanRef.current,
        notes: noteRef.current,
      };
      let index = orderContext.items.findIndex((x) => x.id === item_box.id);
      orderContext.items[index] = tempitem;
      orderContext.store();
    } else {
      orderContext.add_item({ ...item_box, quantity: quanRef.current });
    }

    setShow(true);
  };

  const decNum = () => {
    if (quanRef.current > 1) {
      setQuan((prevQuan) => prevQuan - 1);
      item_box.offer_price != null
        ? orderContext.setTotal((prev) => prev - item_box.offer_price)
        : orderContext.setTotal((prev) => prev - item_box.price);

      let tempitem = {
        ...item_box,
        quantity: quanRef.current,
        notes: noteRef.current,
      };
      let index = orderContext.items.findIndex((x) => x.id === item_box.id);
      orderContext.items[index] = tempitem;
      orderContext.store();
    } else {
      setQuan((prevQuan) => prevQuan - 1);
      orderContext.remove_item(item_box.id);

      item_box.offer_price != null
        ? orderContext.setTotal((prev) => prev - item_box.offer_price)
        : orderContext.setTotal((prev) => prev - item_box.price);

      orderContext.store();
    }
    if (quanRef.current === 0) {
      setNote("");
    }
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
    let tempitem = {
      ...item_box,
      notes: e.target.value,
      quantity: quanRef.current,
    };
    let index = orderContext.items.findIndex((x) => x.id === item_box.id);
    orderContext.items[index] = tempitem;
    orderContext.store();
  };

  const handleAddNote = () => {
    setNoteBtn(true);
  };

  return (
    <div
      className="box position-relative box-item"
      key={item_box.id}
      id={item_box.id}
    >
      <div className="box-img mb-2 position-relative">
        <img
          src={
            item_box.image != null ? `${STORAGE}${item_box.image}` : emptyImage
          }
          alt="category"
        />
        {item_box.is_trending === 1 && (
          <div className="position-absolute bottom-0 end-0">
            <img
              src={require("../../assets/img/fire-icon.png")}
              className="fire-icon"
              alt="fire-icon"
            />
          </div>
        )}
      </div>
      <p className="fw-bold mb-2"> {item_box.translations.name[curr_lang]}</p>
      <p className="mb-2">{item_box.translations.description[curr_lang]}</p>
      <div className="row align-items-center">
        <div className="col-6">
          <div className="price mb-0">
            {item_box.offer_price !== null ? (
              <div>
                <span className="new-price fw-bold">
                  {item_box.offer_price} {orderContext.currencyState}
                </span>
                <span className="old-price">{item_box.price} {orderContext.currencyState}</span>
              </div>
            ) : (
              <div className="new-price fw-bold">{item_box.price} {orderContext.currencyState}</div>
            )}
          </div>
        </div>

        <div
          className="col-6 d-flex flex-row-reverse align-items-center "
          style={{ justifyContent: "end" }}
        >
          <button
            type="button"
            onClick={incNum}
            className="inc border-0 btn-quan"
          >
            <i className="fas fa-plus"></i>
          </button>
          {show && (
            <>
              {quanRef.current === 0 ? (
                setShow(false)
              ) : (
                <span className="num">{quanRef.current}</span>
              )}
              <button type="button" onClick={decNum} className="btn-min">
                <i className="fas fa-minus"></i>
              </button>
            </>
          )}
        </div>
        {show && (
          <>
            <button
              className="border-0 bg-transparent fw-bold text-start my-2"
              onClick={handleAddNote}
              style={{ color: "#40C3D4" }}
            >
              {t("AddNoteButton")}
            </button>

            {noteBtn && (
              <TextField
                id="filled-basic"
                label={t("AddNoteText")}
                variant="filled"
                multiline
                fullWidth
                rows={4}
                className="note-input"
                value={noteRef.current}
                onChange={handleChangeNote}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemBox;
