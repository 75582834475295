import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { OrderContextProvider } from "./store/OrderContext";
import "./i18n";
import { LangContextProvider } from "./store/LangContext";
import { requestForToken } from "./requestNotificationPermission";

const root = ReactDOM.createRoot(document.getElementById("root"));

requestForToken();

root.render(
  <LangContextProvider>
    <OrderContextProvider>
      <App />
    </OrderContextProvider>
  </LangContextProvider>
);

reportWebVitals();
