import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";

export const requestForToken = () => {
  if (!messaging) {
    console.warn("Firebase messaging is not supported.");
    return;
  }

  return getToken(messaging, { vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other operation you need with the token
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // Show permission request UI
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};
