import { Container } from '@mui/material'

const PreLoder = () => {
    return (
        <Container maxWidth="sm" className="mt-4 text-center position-relative">
            <div className='position-absolute preloder-img'>
            <img src={require('../assets/img/logo.png')} alt="logo" width="250px" />
            </div>
            <Container maxWidth="sm" className="bottom-footer logo-bottom " sx={{ padding:'0' }}>
            {/* <img src={require('../assets/img/logo-zucodia.png')} alt="logo-zucodia" width="200px" /> */}
            </Container>
        </Container>

    )
}

export default PreLoder