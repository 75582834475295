import { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from '@mui/material';
import LanguageDropDown from '../../components/LanguageDropDown';
import CategoryPage from '../categories/CategoryPage';
import Table from '../table/Table';
import { SOCIAL, STORAGE } from '../../constants/urls';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const token = localStorage.getItem("token");
  const [social, setSocial] = useState({});
  const [logo, setLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");

  const { t } = useTranslation();
  const FetchSocialMedia = async () => {
    await axios
      .get(SOCIAL)
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          const data = response.data.data;
          setSocial(data);
          setLogo(data.logo);
          setCoverPhoto(data.cover_photo);

          // Store WhatsApp number in local storage with expiration
          const whatsappNumber = data.whatsapp;
          const expirationTime = new Date().getTime() + 6 * 60 * 60 * 1000; // 6 hours from now
          localStorage.setItem("whatsappNumber", JSON.stringify({ whatsappNumber, expirationTime }));
        }
      })
      .catch((error) => {
        console.error("Error fetching social media data", error);
      });
  };

  useEffect(() => {
    FetchSocialMedia();
  }, []);

  return (
    <>
      {token === null ? (
        <Table />
      ) : (
        <Container
          maxWidth="sm"
          sx={{ padding: "0" }}
          className="bg-white page px-0"
        >
          <div
            className="banner-intro"
            style={{
              backgroundImage: `url(${STORAGE + coverPhoto})`,
            }}
          >
            <div className="text-end">
              <LanguageDropDown />
            </div>
            <div className="image-container">
              <img
                src={STORAGE + logo || "https://i.imgur.com/z7ZwSb7.png"}
                alt="logo"
              />
            </div>
          </div>
          <div className="banner-title">
            <h3 className="fw-bold mb-0">{t("home_title")}</h3>
            {Object.keys(social).length !== 0 && (
              <div className="social-links">
                {social.facebook && (
                  <a href={`https://${social.facebook}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {social.instagram && (
                  <a href={`https://${social.instagram}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
                {social.youtube && (
                  <a href={`https://${social.youtube}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"></i>
                  </a>
                )}
                {social.whatsapp && (
                  <a href={`https://wa.me/${social.whatsapp}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-whatsapp"></i>
                  </a>
                )}
                {social.snapchat && (
                  <a href={`https://${social.snapchat}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-snapchat-square"></i>
                  </a>
                )}
                {social.tiktok && (
                  <a href={`https://${social.tiktok}`} target="_blank" rel="noopener noreferrer">
                    <img
                      src={require("../../assets/img/tiktok.png")}
                      alt="tik-tok"
                    />
                  </a>
                )}
              </div>
            )}
          </div>

          <CategoryPage />
        </Container>
      )}
    </>
  );
};

export default Home;
