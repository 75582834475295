import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import LangContext from "../store/LangContext";
import { useTranslation } from "react-i18next";

const LanguageDropDown = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const langsContext = useContext(LangContext);

  const handleChange = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("lang", event.target.value);
  };

  useEffect(() => {
    const _lang = localStorage.getItem("lang") ?? "en";
    i18n.changeLanguage(_lang);
    setLang(_lang);
  }, [i18n]);

  return (
    <FormControl
      variant="filled"
      sx={{ minWidth: 120 }}
      size="small"
      className="lang-select"
    >
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={lang}
        onChange={handleChange}
      >
        {langsContext?.langs?.length > 0 &&
          langsContext.langs.map((item) => (
            <MenuItem value={item.code} key={item.id}>
              {item.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default LanguageDropDown;
