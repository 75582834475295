import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import LangContext from '../store/LangContext';
import { FormControl, Select, MenuItem, Button } from '@mui/material';

Modal.setAppElement('#root'); // Ensure this line if you're using accessibility features

const LanguageSelectionModal = ({ isOpen, onClose, logo }) => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const langsContext = useContext(LangContext);

  const handleChange = (event) => {
    setLang(event.target.value);
  };

  const handleSave = () => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Language Selection Modal"
      className="styled-modal"
      overlayClassName="styled-overlay"
    >
      <img src={logo} alt="Logo" className="modal-logo" />
      <h2 className="modal-title">Select Language</h2>
      <FormControl variant="filled" sx={{ minWidth: 120 }} size="small" className="styled-form-control">
        <Select value={lang} onChange={handleChange} className="styled-select">
          {langsContext?.langs?.length > 0 &&
            langsContext.langs.map((item) => (
              <MenuItem value={item.code} key={item.id} className="styled-menu-item">
                {item.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button onClick={handleSave} variant="contained" color="primary" className="styled-save-button">
        Save
      </Button>
    </Modal>
  );
};

export default LanguageSelectionModal;
