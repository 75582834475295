import { Alert, AlertTitle, CircularProgress, Container, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { RESERVATION } from "../../constants/urls";
import { useTranslation } from "react-i18next";

const Table = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [tablenum, setTablenum] = useState('');
    const [showerror, setShowError] = useState(false);
    const [erroralert, showErrorAlert] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const requireLocation = process.env.REACT_APP_REQUIRE_LOCATION === 'true';

    const onchange = (e) => {
        if (e.target.value > 0) {
            setTablenum(e.target.value);
        }
    }

    const ReservationTable = async () => {
        setLoading(true);

        if (!tablenum) {
            showErrorAlert(t('error.validTableNumber'));
            setShowError(true);
            setLoading(false);
            return;
        }

        if (requireLocation && (!latitude || !longitude)) {
            showErrorAlert(t('error.unableToFetchLocation'));
            setShowError(true);
            setLoading(false);
            return;
        }

        const data = {
            table_number: tablenum,
            latitude: latitude || '',
            longitude: longitude || ''
        };

        console.log('Sending data to server:', data);

        try {
            const response = await axios.post(RESERVATION, data);
            console.log('Server response:', response);
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("reservation_id", response.data.data.reservation_id); // Store reservation ID
            setShowError(false);
            window.location.reload();
        } catch (error) {
            console.error('Error response:', error.response);
            setShowError(true);
            showErrorAlert(t('error.validTableNumber'));
        }
        setLoading(false);
    }

    // Get current user location
    useEffect(() => {
        if (requireLocation && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error, { timeout: 10000 });
        } else if (!requireLocation) {
            setShowError(false);
        } else {
            showErrorAlert(t('error.geolocationNotSupported'));
            setShowError(true);
        }
    }, [requireLocation]);

    function success(position) {
        console.log('Geolocation success:', position);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setShowError(false);
    }

    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        switch (err.code) {
            case err.PERMISSION_DENIED:
                showErrorAlert(t('error.locationPermissionDenied'));
                break;
            case err.POSITION_UNAVAILABLE:
                showErrorAlert(t('error.locationUnavailable'));
                break;
            case err.TIMEOUT:
                showErrorAlert(t('error.locationRequestTimeout'));
                break;
            default:
                showErrorAlert(t('error.unknownError'));
                break;
        }
        setShowError(true);
    }

    return (
        <Container maxWidth="sm" className="table-screen text-center bg-white">
            {showerror &&
                <Alert severity="error" className='mb-4'>
                    <AlertTitle>{t('error.title')}</AlertTitle>
                    {erroralert}
                </Alert>
            }
            <h4 className="fw-bold" style={{ color: '#FAA61A' }}>{t('tableNumber')}</h4>
            <h6 className="mb-5">{t('tableNumberDescription')}</h6>
            <TextField
                id="standard-basic"
                label={t('tableNumber')}
                variant="standard"
                value={tablenum}
                onChange={onchange}
                type="number"
                className="mb-5"
            />
            <Container maxWidth="sm">
                {loading ? (
                    <div>
                        <CircularProgress style={{ color: '#FAA61A' }} />
                        <p style={{ color: '#FAA61A' }}>{t('loading')}</p>
                    </div>
                ) : (
                    <button onClick={ReservationTable}>{t('submit')}</button>
                )}
            </Container>
        </Container>
    )
}

export default Table;
