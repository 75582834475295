import PreviosPage from "../../components/PreviosPage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Container, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { ABOUT, STORAGE } from "../../constants/urls";
import emptyImage from '../../assets/img/no-image.png';
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const About = () => {
    const { t } = useTranslation();
    const curr_lang = i18n.language;
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [info, setInfo] = useState(null);

    const FetchCategories = async () => {
        setLoading(true);
        if (token != null) {
            await axios.get(ABOUT)
                .then((response) => {
                    if (response.status === 200) {
                        setInfo(response.data.data);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error.status);
                    setLoading(false);
                });
        } else {
            navigate("/");
        }
    }

    useEffect(() => {
        FetchCategories();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Container maxWidth="sm" className="pt-4 bg-white page about-page">
            <div className='row align-items-center mb-4'>
                <div className='col-3'><PreviosPage /></div>
                <div className='col-6'>
                    <h2 className="title fw-bold text-center mb-0" style={{ color: '#FAA61A' }}>{t("about_title")}</h2>
                </div>
                <div className='col-3'></div>
            </div>
            {loading ? <LoadingSkeleton /> :
                <>
                    {info != null ?
                        <div className="content">
                            <Slider {...settings} className="main-slider mb-5">
                                {info.images.length !== 0 && info.images.map((i) => (
                                    <div className="item" key={i.id}>
                                        <img src={i.image != null ? `${STORAGE}${i.image}` : emptyImage} alt="about" width="100%" />
                                    </div>
                                ))}
                            </Slider>
                            <h2 className="title fw-bold mb-2">{info.translations.title[curr_lang]}</h2>
                            <p className="mb-4">{info.translations.bio[curr_lang]}</p>
                            <ul className="list-unstyled m-0 list-about">
                                {info.address && (
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="far fa-map-marker-alt"></i><p>{info.address}</p>
                                    </li>
                                )}
                                {info.wifi && (
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="fas fa-wifi"></i><p>{info.wifi}</p>
                                    </li>
                                )}
                                {info.tel && (
                                    <li>
                                        <a href={`tel:${info.tel}`} className="d-flex align-items-center mb-5"><i className="far fa-phone-alt"></i><p>{info.tel}</p></a>
                                    </li>
                                )}
                            </ul>
                            <h5 className="fw-bold mb-4">{t("Follow")}</h5>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                                {info.facebook && (
                                    <a href={`https://${info.facebook}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                )}
                                {info.instagram && (
                                    <a href={`https://${info.instagram}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                )}
                                {info.youtube && (
                                    <a href={`https://${info.youtube}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                                )}
                                {info.whatsapp && (
                                    <a href={`https://wa.me/${info.whatsapp}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                                )}
                                {info.snapchat && (
                                    <a href={`https://${info.snapchat}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-snapchat-square"></i></a>
                                )}
                                {info.tiktok && (
                                    <a href={`https://${info.tiktok}`} target="_blank" rel="noopener noreferrer">
                                        <img src={require('../../assets/img/tiktok.png')} alt="tik-tok" style={{ width: '19px', position: 'relative', top: '-2px' }} />
                                    </a>
                                )}
                            </Box>
                        </div>
                        : <Typography variant="h6" className='text-center' sx={{ mb: 5 }}>{t("NoInformationAbout")}</Typography>
                    }
                </>
            }
        </Container>
    );
}

export default About;
