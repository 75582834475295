import React, { useState, useEffect } from "react";
import "./assets/css/style.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Footer from "./pages/footer/Footer";
import Home from "./pages/Home/Home";
import ItemPage from "./pages/items/ItemPage";
import OrdersPage from "./pages/order/OrdersPage";
import About from "./pages/about/About";
import PreLoder from "./components/PreLoder";
import { messaging, onMessage } from "./firebase";
import { useTranslation } from "react-i18next";
import './assets/css/fonts.css';  // Ensure this path is correct and includes your font imports
import LanguageSelectionModal from "./components/LanguageSelectionModal"; // Import the modal component
import axios from 'axios';
import { SOCIAL, STORAGE } from './constants/urls'; // Adjust the paths as necessary

export default function App() {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logo, setLogo] = useState("");
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const fetchLogo = async () => {
    try {
      const response = await axios.get(SOCIAL);
      if (response.status === 200 && response.data.status === "success") {
        setLogo(response.data.data.logo);
      }
    } catch (error) {
      console.error("Error fetching logo", error);
    }
  };

  useEffect(() => {
    fetchLogo();
    setTimeout(() => setLoading(false), 2000);
    const lang = localStorage.getItem('lang');
    if (!lang) {
      setIsModalOpen(true);
    }

    if (onMessage) {
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        if (payload.data.type === 'reservation_ended') {
          localStorage.clear();
          window.location.reload(); // Reload to reflect the changes
        }
      });
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getFontClass = () => {
    if (currentLanguage === 'ar' || currentLanguage === 'en') {
      return 'almarai-regular';
    } else if (currentLanguage === 'ku') {
      return 'shasenem-light';
    }
    return ''; // Default class if not Arabic, English, or Kurdish
  };

  return (
    <div className={getFontClass()}>
      {loading === false ? (
        <BrowserRouter>
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<ItemPage />} path="/items/:id" />
            <Route element={<ItemPage />} path="/items/trend" />
            <Route element={<OrdersPage />} path="/orders" />
            <Route element={<About />} path="/about-us" />
          </Routes>
          <Footer />
          <LanguageSelectionModal isOpen={isModalOpen} onClose={handleCloseModal} logo={STORAGE + logo} /> {/* Pass the logo */}
        </BrowserRouter>
      ) : (
        <PreLoder />
      )}
    </div>
  );
}
