import { initializeApp } from "firebase/app";
import { getMessaging, onMessage as firebaseOnMessage, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

let messaging = null;
let onMessage = null;

isSupported()
  .then((supported) => {
    if (supported) {
      messaging = getMessaging(app);
      onMessage = firebaseOnMessage;
    } else {
      console.warn("Firebase messaging is not supported in this browser.");
    }
  })
  .catch((err) => {
    console.error("Error checking messaging support: ", err);
  });

export { messaging, onMessage };
