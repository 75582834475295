import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Rating,
  Typography,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useState } from "react";
import { BILL, NEED_HELP, PRIVACY } from "../../constants/urls";
import axios from "axios";
import { useTranslation } from "react-i18next";
import OrderContext from "../../store/OrderContext";
import { getStoredWhatsAppNumber } from "../../utils/utils";

const Footer = () => {
  const orderContext = useContext(OrderContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [ratings, setRatings] = useState([0, 0, 0]);
  const [message, setMessage] = useState(false);
  const splitLocation = pathname.split("/");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(false);
  };

  const handlePrivacy = async () => {
    setLoading(true);
    const data = {};
    await axios
      .post(PRIVACY, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        setMessage(true);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          localStorage.removeItem("token");
          navigate("/");
        }
      });
    setLoading(false);
  };

  const handleNeedHelp = async () => {
    setLoading(true);
    const data = {};
    await axios
      .post(NEED_HELP, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        setMessage(true);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          localStorage.removeItem("token");
          navigate("/");
        }
      });
    setLoading(false);
  };

  const handleBill = () => {
    setReviewDialogOpen(true);
  };

  const submitReviewAndBill = async () => {
    setReviewDialogOpen(false);
    setLoading(true);
  
    // Here you can handle the review submission if needed
    console.log("Ratings:", ratings);
  
    const data = {};
    await axios
      .post(BILL, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        setOpen(false);
        setTimeout(() => {
          window.location.reload();
          localStorage.removeItem("token");
          navigate("/");
        }, 5000);
  
        // Send WhatsApp message
        const whatsappNumber = getStoredWhatsAppNumber();
        if (whatsappNumber) {
          const message = `Your review has been submitted successfully! Ratings: ${ratings.join(", ")}`;
          window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`, "_blank");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          localStorage.removeItem("token");
          navigate("/");
        }
      });
    setLoading(false);
  };  

  const handleNoThanks = () => {
    setReviewDialogOpen(false);
    submitReviewAndBill();
  };

  const handleClickRef = () => {
    if (pathname === "/" || pathname === "/about-us") {
      navigate("/items/trend");
      setTimeout(() => {
        orderContext.refInput.current.focus();
      }, 500);
    } else {
      orderContext.refInput.current.focus();
    }
    const btnSearch = document.querySelector(".btn-srh");
    document.addEventListener("click", (event) => {
      if (event.target !== btnSearch) {
        btnSearch.classList.remove("active");
      } else {
        btnSearch.classList.add("active");
      }
    });
  };

  let displayStyle = "";
  if (pathname === "/orders") {
    displayStyle = "none";
  }

  return (
    <>
      {token ? (
        <>
          <div
            open={open}
            className="blur-section"
            style={
              open
                ? {
                    opacity: "1",
                    zIndex: "1",
                    position: "fixed",
                    width: "100%",
                    left: "0",
                    top: "0",
                  }
                : { opacity: "0" }
            }
          ></div>
          <Container
            maxWidth="sm"
            style={{ display: displayStyle }}
            sx={{ padding: "0" }}
          >
            <Container
              maxWidth="sm"
              className="bottom-footer px-0"
              sx={{ padding: "0" }}
            >
              <footer className="position-relative ">
                <div className="help-section">
                  <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={open}
                        onClick={handleClose}
                      >
                        <div className="loading-bg text-center">
                          <CircularProgress color="inherit" size={50} />
                          <br></br>
                          <div className="title text-white">{t("message_wait")}</div>
                        </div>
                      </Backdrop>
                    ) : (
                      <SpeedDial
                        ariaLabel="SpeedDial openIcon example"
                        icon={<SpeedDialIcon openIcon={<CloseIcon />} />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                      >
                        <SpeedDialAction
                          tooltipTitle="Privacy"
                          onClick={handlePrivacy}
                        />
                        <SpeedDialAction
                          tooltipTitle="Need Help"
                          onClick={handleNeedHelp}
                        />
                        <SpeedDialAction
                          tooltipTitle="Bill"
                          onClick={handleBill}
                        />
                      </SpeedDial>
                    )}
                  </Box>
                </div>

                <div className="row justify-content-center row-links">
                  <div className="col-3 text-center">
                    <NavLink className={splitLocation[1] === "/" ? "active" : ""} to="/">
                      <i className="far fa-home-alt"></i>
                      {/* <span>{t("home_link")}</span> */}
                    </NavLink>
                  </div>
                  <div className="col-3 text-center">
                    <NavLink className={splitLocation[1] === "orders" ? "active" : ""} to="/orders">
                      <i className="far fa-heart"></i>
                      {/* <span> {t("myOrder_link")}</span> */}
                    </NavLink>
                  </div>
                  <div className="col-3 text-center">
                    <button className="btn-srh" onClick={handleClickRef}>
                      <i className="fal fa-search"></i>
                      {/* <span> {t("search_link")}</span> */}
                    </button>
                  </div>
                  <div className="col-3 text-center">
                    <NavLink className={splitLocation[1] === "about-us" ? "active" : ""} to="/about-us">
                      <i className="fal fa-info-circle"></i>
                      {/* <span>{t("about_link")}</span> */}
                    </NavLink>
                  </div>
                </div>
              </footer>
            </Container>
          </Container>
          <Snackbar
            open={message}
            autoHideDuration={4000}
            onClose={handleCloseMessage}
            className="ic"
          >
            <Alert
              onClose={handleCloseMessage}
              severity="success"
              sx={{ width: "100%" }}
              style={{ background: "rgb(46, 125, 50)", color: "#fff" }}
            >
              {t("success_message")}
            </Alert>
          </Snackbar>

          {/* Review Dialog */}
          <Dialog open={reviewDialogOpen} onClose={() => setReviewDialogOpen(false)}>
            <DialogTitle>{t("Review")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("Please rate the following aspects of our service:")}
              </DialogContentText>
              {["Quality", "Speed", "Overall"].map((label, index) => (
                <Box key={index} sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>{t(label)}</Typography>
                  <Rating
                    name={`rating-${index}`}
                    value={ratings[index]}
                    onChange={(event, newValue) => {
                      const newRatings = [...ratings];
                      newRatings[index] = newValue;
                      setRatings(newRatings);
                    }}
                    sx={{ color: "gold" }} // Set star color to gold
                  />
                </Box>
              ))}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
              <Button onClick={handleNoThanks} variant="outlined" color="secondary">
                {t("No, Thanks")}
              </Button>
              <Button onClick={submitReviewAndBill} variant="contained" color="primary">
                {t("Submit")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Footer;
