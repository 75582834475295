import { useState, useCallback, useContext } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrderContext from "../../store/OrderContext";
import { STORAGE } from "../../constants/urls";
import i18n from "../../i18n";

const ItemOrder = ({ item_box }) => {
  const { t } = useTranslation();
  const curr_lang = i18n.language;
  const orderContext = useContext(OrderContext);
  const [quan, setQuan] = useState(item_box.quantity);
  const [noteBtn, setNoteBtn] = useState(false);
  const [note, setNote] = useState(item_box.notes || "");

  const handleChangeNote = useCallback((e) => {
    setNote(e.target.value);
    const tempItem = {
      ...item_box,
      notes: e.target.value,
      quantity: quan,
    };
    const index = orderContext.items.findIndex((x) => x.id === item_box.id);
    const updatedItems = [...orderContext.items];
    updatedItems[index] = tempItem;
    orderContext.setItems(updatedItems);
    orderContext.store();
  }, [item_box, quan, orderContext]);

  const incNum = useCallback(() => {
    setQuan((prevQuan) => {
      const newQuan = prevQuan + 1;
      const updatedPrice = item_box.offer_price != null ? item_box.offer_price : item_box.price;
      orderContext.setTotal((prev) => prev + parseInt(updatedPrice));

      const tempItem = { ...item_box, quantity: newQuan };
      const index = orderContext.items.findIndex((x) => x.id === item_box.id);
      const updatedItems = [...orderContext.items];
      updatedItems[index] = tempItem;
      orderContext.setItems(updatedItems);
      orderContext.store();
      return newQuan;
    });
  }, [item_box, orderContext]);

  const decNum = useCallback(() => {
    setQuan((prevQuan) => {
      if (prevQuan > 1) {
        const newQuan = prevQuan - 1;
        const updatedPrice = item_box.offer_price != null ? item_box.offer_price : item_box.price;
        orderContext.setTotal((prev) => prev - parseInt(updatedPrice));

        const tempItem = { ...item_box, quantity: newQuan };
        const index = orderContext.items.findIndex((x) => x.id === item_box.id);
        const updatedItems = [...orderContext.items];
        updatedItems[index] = tempItem;
        orderContext.setItems(updatedItems);
        orderContext.store();
        return newQuan;
      } else {
        orderContext.remove_item(item_box.id);
        const updatedPrice = item_box.offer_price != null ? item_box.offer_price : item_box.price;
        orderContext.setTotal((prev) => prev - parseInt(updatedPrice));
        return 0;
      }
    });
  }, [item_box, orderContext]);

  const handleAddNote = () => {
    setNoteBtn(true);
  };

  return (
    <div className="main-box">
      <div className="box position-relative box-order mb-4" key={item_box.id} id={item_box.id}>
        <div className="row">
          <div className="col-sm-4 text-center">
            <p className="fw-bold mb-4 title-order">{item_box.translations.name[curr_lang]}</p>
            <p className="price mb-2 fw-bold">
              {item_box.offer_price !== null ? (
                <div>
                  <span className="new-price fw-bold text-white">
                    {item_box.offer_price} {orderContext.currencyState}
                  </span>
                  <span className="old-price" style={{ color: "#404242b0" }}>
                    {item_box.price} {orderContext.currencyState}
                  </span>
                </div>
              ) : (
                <div className="new-price fw-bold text-white">
                  {item_box.price} {orderContext.currencyState}
                </div>
              )}
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <button onClick={incNum} className="btn-quan" type="button">
                <i className="fas fa-plus"></i>
              </button>
              <span className="num text-white">{quan}</span>
              <button onClick={decNum} className="btn-quan" type="button">
                <i className="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div className="col-sm-8 text-center">
            <img src={item_box.image ? `${STORAGE}${item_box.image}` : ''} alt="category" />
          </div>
        </div>
      </div>
      <div className="note-section mb-4 ps-3">
        {item_box.notes ? (
          <>
            <p className="mb-0">{t("note_text")}</p>
            <div>{item_box.notes}</div>
          </>
        ) : (
          <>
            <button
              className="border-0 bg-transparent fw-bold text-start my-2"
              onClick={handleAddNote}
              style={{ color: "#40C3D4" }}
            >
              {t("AddNoteButton")}
            </button>
            {noteBtn && (
              <TextField
                id="filled-basic"
                label={t("AddNoteText")}
                variant="filled"
                multiline
                fullWidth
                rows={4}
                className="note-input"
                value={note}
                onChange={handleChangeNote}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemOrder;